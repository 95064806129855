<template>
  <div class="privacy-wrapper">
    <h3 class="privacy-title">Kebijakan & Privasi</h3>
    <h5 class="privacy-category">
      Sarung Indonesia | Platform Reseller Sarung Pertama di Indonesia
    </h5>
    <hr class="line-content mt-3">
    <p>
      Kami sangat mengerti dan menghargai kepentingan pengguna atas hak
      privasi dan keamanan akan setiap informasi pribadi yang akan diberikan
      secara online ketika berkunjung ke website store sarung indonesia.
      Privacy Policy ini menjelaskan komitmen kami akan kerahasiaan dan
      keamanan data pribadi pengguna baik ketika mengunjungi website store
      kami atau saat pengguna memutuskan untuk menjadi pelanggan kami.
    </p>
    <p class="mt-4">
      Dengan mengakses dan/atau mempergunakan layanan Sarung Indonesia,
      pengguna menyatakan bahwa setiap data pengguna merupakan data yang
      benar dan sah, serta pengguna memberikan persetujuan kepada Sarung
      Indonesia untuk memperoleh, mengumpulkan, menyimpan, mengelola dan
      mempergunakan data tersebut sebagaimana tercantum dalam Kebijakan
      Privasi maupun Syarat dan Ketentuan Sarung Indonesia.
    </p>

    <div class="privacy-content">
      <h4 class="privacy-subtitle">Pengumpulan Data Informasi</h4>
      <p class="privacy-paragraph">
        Sarung Indonesia mengumpulkan data pengguna dengan tujuan untuk
        memproses transaksi pengguna, mengelola dan memperlancar proses
        penggunaan website, serta tujuan-tujuan lainnya selama diizinkan
        oleh peraturan perundang-undangan yang berlaku. Adapun data pengguna
        yang dikumpulkan adalah sebagai berikut:
      </p>

      <ol>
        <li>
          Mempergunakan layanan-layanan pada website store, termasuk data
          transaksi yang detail, diantaranya jenis, jumlah dan/atau
          keterangan dari produk atau layanan yang dibeli, alamat
          pengiriman, kanal pembayaran yang digunakan, jumlah transaksi,
          tanggal dan waktu transaksi, serta detail transaksi lainnya.
        </li>
        <li>
          Menjaga informasi pribadi pengguna, jika kami membutuhkan
          informasi pribadi tambahan. Kami akan mengumpulkannya dan tetap
          menjaga kerahasiaan data ini.
        </li>
        <li>
          Mengisi survei yang dikirimkan atas nama Sarung Indonesia
          Menggunakan fitur yang membutuhkan izin akses terhadap perangkat
          pengguna.
        </li>
      </ol>
    </div>

    <div class="privacy-content">
      <h4 class="privacy-subtitle">Keamanan Penggunaan Data</h4>
      <p class="privacy-paragraph">
        Sarung Indonesia dapat menggunakan keseluruhan atau sebagian data
        yang diperoleh dan dikumpulkan dari pengguna sebagaimana disebutkan
        dalam bagian sebelumnya untuk hal-hal sebagai berikut:
      </p>

      <ol>
        <li>
          Memproses segala bentuk permintaan, aktivitas maupun transaksi
          yang dilakukan oleh pengguna melalui website store, termasuk untuk
          keperluan pengiriman produk kepada pengguna.
        </li>
        <li>
          Penyediaan fitur-fitur untuk memberikan, mewujudkan, memelihara
          dan memperbaiki produk dan layanan kami, termasuk:
        </li>
        <li>
          Menawarkan, memperoleh, menyediakan, atau memfasilitasi layanan
          produk melalui website store.
        </li>
        <li>
          Melakukan kegiatan internal yang diperlukan untuk menyediakan
          layanan pada website store, seperti pemecahan masalah software,
          bug, permasalahan operasional, melakukan analisis data, pengujian,
          dan penelitian, dan untuk memantau dan menganalisis kecenderungan
          penggunaan dan aktivitas.
        </li>
        <li>
          Menghubungi pengguna melalui email, surat, telepon, fax, dan
          lain-lain, termasuk namun tidak terbatas, untuk membantu dan/atau
          menyelesaikan proses transaksi maupun proses penyelesaian kendala.
        </li>
        <li>
          Membantu Pengguna pada saat berkomunikasi dengan Layanan Pelanggan
          Sarung Indonesia, diantaranya untuk, memeriksa dan mengatasi
          permasalahan pengguna serta mengawasi dan memperbaiki tanggapan
          Layanan Pelanggan Sarung Indonesia.
        </li>
        <li>
          Menginformasikan kepada pengguna terkait produk, layanan, promosi,
          berita, perkembangan terbaru, acara dan lain-lain, baik melalui
          website store maupun melalui media lainnya.
        </li>
        <li>
          Melakukan monitoring ataupun investigasi terhadap
          transaksi-transaksi mencurigakan atau transaksi yang terindikasi
          mengandung unsur kecurangan atau pelanggaran terhadap Syarat dan
          Ketentuan atau ketentuan hukum yang berlaku, serta melakukan
          tindakan-tindakan yang diperlukan sebagai tindak lanjut dari hasil
          monitoring atau investigasi transaksi tersebut.
        </li>
        <li>
          Dalam keadaan tertentu, Sarung Indonesia mungkin perlu untuk
          menggunakan ataupun mengungkapkan data pengguna untuk tujuan
          penegakan hukum atau untuk pemenuhan persyaratan hukum dan
          peraturan yang berlaku, termasuk dalam hal terjadinya sengketa
          atau proses hukum antara pengguna dan Sarung Indonesia
        </li>
      </ol>
    </div>

    <div class="privacy-content">
      <h4 class="privacy-subtitle">Cookies</h4>
      <p class="privacy-paragraph">
        Cookies adalah sebuah file kecil yang secara otomatis akan mengambil
        tempat di dalam perangkat pengguna yang menjalankan fungsi dalam
        menyimpan preferensi maupun konfigurasi pengguna selama mengunjungi
        suatu situs.
      </p>
      <p class="privacy-paragraph">
        Cookies tersebut tidak diperuntukkan untuk digunakan pada saat
        melakukan akses data lain yang pengguna miliki di perangkat komputer
        pengguna, selain dari yang telah pengguna setujui untuk disampaikan.
      </p>
      <p class="privacy-paragraph">
        Walaupun secara otomatis perangkat komputer pengguna akan menerima
        cookies, pengguna dapat menentukan pilihan untuk melakukan
        modifikasi melalui pengaturan browser Pengguna yaitu dengan memilih
        untuk menolak cookies (pilihan ini dapat membatasi layanan optimal
        pada saat melakukan akses ke website).
      </p>
    </div>

    <div class="privacy-content">
      <h4 class="privacy-subtitle">Hubungi Kami</h4>
      <p class="privacy-paragraph">
        Pengguna dapat menghubungi Sarung Indonesia terkait Kebijakan
        Privasi ini melalui cs@sarungindonesia.co.id
      </p>
      <p class="privacy-paragraph">
        Sarung Indonesia dapat sewaktu-waktu melakukan perubahan atau
        pembaruan terhadap Kebijakan Privasi ini. Sarung Indonesia
        menyarankan agar pengguna membaca secara seksama dan memeriksa
        halaman Kebijakan Privasi ini dari waktu ke waktu untuk mengetahui
        perubahan apapun. Dengan tetap mengakses dan menggunakan layanan
        website store maka pengguna dianggap menyetujui perubahan-perubahan
        dalam Kebijakan Privasi.
      </p>
    </div>
  </div>
</template>
<script>

export default {
  name: 'component.privacy-policy',
}
</script>
<style lang="scss" scoped>
.privacy-wrapper {
  width: 100%;
  margin: 0 auto;
}

h3.privacy-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: var(--biz-primary-100);

  @media (max-width:576px) {
    font-size: 18px;
    line-height: 20px;
  }
}

h5.privacy-category {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #777777;
  flex: none;
  order: 1;
  flex-grow: 0;

  @media (max-width:576px) {
    font-size: 14px;
    line-height: 20px;
  }
}

p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #000000;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 5rem;

  @media (max-width:576px) {
    font-size: 12px;
    line-height: 20px;
    margin-top: 2rem;
  }
}

.privacy-content {
  width: 100%;
  margin-top: 30px;

  h4.privacy-subtitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    @media (max-width:576px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  p.privacy-paragraph {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: justify;
    color: var(--biz-primary-100);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    @media (max-width: 576px) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .privacy-content {
    width: 100%;
    margin-top: 30px;

    h4.privacy-subtitle {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 32px;
      color: var(--biz-primary-100);
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }
  }

  p.privacy-paragraph {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: justify;
    color: var(--biz-primary-100);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    @media (max-width: 576px) {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }

  ol {
    margin: 0;
    padding: 0 0 0 15px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 576px) {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }

    li {
      text-align: justify;
    }
  }
}
</style>
