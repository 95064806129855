<template>
  <div class="about-wrapper-views">
    <PrivacyPolicy />
  </div>
</template>
<script>
import PrivacyPolicy from '@/components/About/PolicyPrivacy.vue'
export default {
  components: { PrivacyPolicy },
  name: 'about-bhs',
}
</script>
<style scoped lang="scss">
.about-wrapper-views {
  width: 80%;
  margin: 0 auto;

  @media (max-width: 567px) {
    width: 95%;
  }
}
</style>
